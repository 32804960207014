import React from "react";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";
import config from "../config";
import axios from "axios";
import LightBox from "./LightBox";
import Sliders from "./Sliders";
import Popup from "./Popup";
import { Link } from "react-router-dom";
import $ from "jquery";
import { RequestContext } from "../notRedux";
import { useHistory } from "react-router-dom";
import { PopupSlidersControl } from "../global/controller";

function Sale() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState([]);
  const [source, setSource] = React.useState("");
  const [videoSource, setVideoSource] = React.useState("");
  // const [slider, setSlider] = React.useState([]);
  const [detail, setDetail] = React.useState({});
  const [floorId, setFloorId] = React.useState("");
  const history = useHistory();

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/sale`,
    }).then((res) => {
      setData(res.data);
    });
  }, []);

  const closeLightBox = () => {
    setSource("");
  };

  const lightBoxHandle = (floor) => {
    /*     const item = data.find((item) => item.id === item_id);
    const floor = item.floor.find((floor) => floor.id === floor_id); */
    const src = config.baseURL + "/storage/" + floor.floor_plan;
    setSource(src);
  };

  const sliderHandle = (title, floor) => {
    PopupSlidersControl.execute(title, floor.image);
    /*     const item = data.find((item) => item.id === item_id);
    const floor = item.floor.find((floor) => floor.id === floor_id); */
    /*     const image = floor.image;
    setSlider(image); */
  };

  /*   const closeSlider = () => {
    setSlider([]);
  }; */

  const popupHandle = (item, floorId) => {
    setDetail(item);
    setFloorId(floorId);
  };

  const closeDetail = () => {
    setFloorId("");
    setDetail({});
  };

  const openContentHandle = (e) => {
    const target = e.target;
    const parent = target.closest(".sale-item");
    if (parent.dataset.state === "close") {
      target.classList.add("active");
      parent.classList.add("active");
      parent.setAttribute("data-state", "open");
      $(parent).find(".sale-content-wapper").slideDown();
    } else {
      target.classList.remove("active");
      parent.classList.remove("active");
      parent.setAttribute("data-state", "close");
      $(parent).find(".sale-content-wapper").slideUp();
    }
  };

  const requestHandle = (e) => {
    const target = e.target;
    const value = target.value;
    const checked = target.checked;
    const item_id = target.dataset.itemId;
    const floor_id = target.dataset.floorId;
    const item = target.dataset.item;
    const floor = target.dataset.floor;
    if (checked) {
      RequestContext.add(item_id, floor_id, item, floor);
    } else {
      RequestContext.remove(item_id, floor_id);
    }
  };

  const sendRequestHandle = () => {
    RequestContext.title = t("for_sale");
    history.push("/contact-us");
  };

  const showVideo = (source) => {
    setVideoSource(source);
  };

  const closeVideo = () => {
    setVideoSource("");
  };

  return (
    <div className="sale page-content">
      <PageTitle title={t("for_sale")} subtitle={t("commercial_residential")} />

      {data &&
        data.map((item, index) => {
          return (
            item.floor.length > 0 && (
              <div key={index} className="sale-item" data-state="close">
                <div className="s-name position-relative text-deep-blue font-weight-bold">
                  {language === "en" ? item.title_en : item.title_tc}
                  <div
                    className="material-icons sale-trigger"
                    onClick={openContentHandle}
                  ></div>
                </div>
                <div className="sale-content-wapper">
                  <div className="position-relative">
                    <div className="s-address">
                      <b>{t("address")}:</b>
                      <div>
                        {language === "en" ? item.address_en : item.address_tc}
                      </div>
                    </div>
                    <div className="sale-property-portfolio-link">
                      {/* {item.portfolio_id && (
                      <Link to={`/property-portfolio`}>
                        <span>{t("view_property_description")}</span>
                        <i className="material-icons">leaderboard</i>
                      </Link>
                    )} */}
                    </div>
                  </div>
                  <div className="sale-table">
                    <div className="sale-row sale-header">
                      <div style={{ width: "10px" }}></div>
                      <div className="text-deep-grey align-top">
                        {t("floor_unit")}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: t("gross_area") }}
                        className="text-deep-grey align-top"
                      ></div>
                      {item.property === "residential" && (
                        <div
                          className="text-deep-grey align-top"
                          dangerouslySetInnerHTML={{
                            __html: t("saleable_area"),
                          }}
                        ></div>
                      )}
                      <div className="text-deep-grey align-top">
                        {t("asking_price")}
                      </div>
                      <div className="text-deep-grey align-top sale-remarks">
                        {t("remarks")}
                      </div>
                      <div className="text-center text-deep-grey align-top">
                        {t("floor_plan")}
                      </div>
                      <div className="text-center text-deep-grey align-top">
                        {t("interior_images")}
                      </div>
                      <div className="text-center text-deep-grey align-top">
                        {t("video")}
                      </div>
                    </div>
                    {item.floor.map((floor, index) => {
                      return (
                        <div className="sale-row" key={index}>
                          <div style={{ width: "10px" }}>
                            <input
                              type="checkbox"
                              onClick={(e) => requestHandle(e)}
                              data-item-id={item.id}
                              data-floor-id={floor.id}
                              data-item={
                                language === "en"
                                  ? item.title_en
                                  : item.title_tc
                              }
                              data-floor={
                                language === "en"
                                  ? floor.floor_en
                                  : floor.floor_tc
                              }
                            />
                          </div>
                          <div className="row">
                            <div className="col mobile-leasing-ttl">
                              {t("floor_unit")}
                            </div>
                            <div className="col">
                              {language === "en"
                                ? floor.floor_en
                                : floor.floor_tc}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col mobile-leasing-ttl">
                              {t("gross_area_1")}
                            </div>
                            <div className="col">
                              {language === "en"
                                ? floor.gross_area_en
                                : floor.gross_area_tc}
                            </div>
                          </div>
                          {item.property === "residential" && (
                            <div className="row">
                              <div className="col mobile-leasing-ttl">
                                {t("saleable_area_1")}
                              </div>
                              <div className="col">
                                {language === "en"
                                  ? floor.saleable_area_en
                                  : floor.saleable_area_tc}
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col mobile-leasing-ttl">
                              {t("asking_price")}
                            </div>
                            <div className="col">
                              {language === "en"
                                ? floor.price_en
                                : floor.price_tc}
                            </div>
                          </div>
                          <div
                            className="sale-show-more-detail cursor-pointer cursor-pointer only-for-desktop"
                            onClick={() => popupHandle(item, floor.id)}
                          >
                            {language === "en"
                              ? floor.remarks_ttl_en
                              : floor.remarks_ttl_tc}
                          </div>
                          <div className="text-center cursor-pointer only-for-desktop">
                            {floor.floor_plan && (
                              <img
                                src="/images/icon_floorplan.gif"
                                className="cursor-pointer"
                                onClick={() => lightBoxHandle(floor)}
                              />
                            )}
                          </div>
                          <div className="text-center cursor-pointer only-for-desktop">
                            {floor.image.length > 0 && (
                              <img
                                src="/images/icon_camera.gif"
                                className="cursor-pointer"
                                onClick={() =>
                                  sliderHandle(
                                    language === "en"
                                      ? item.title_en
                                      : item.title_tc,
                                    floor
                                  )
                                }
                              />
                            )}
                          </div>
                          <div className="text-center cursor-pointer only-for-desktop">
                            {floor.video && (
                              <div onClick={() => showVideo(floor.video)}>
                                <svg
                                  className="w-4 h-4"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    width: 17,
                                    height: 17,
                                    cursor: "pointer",
                                  }}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>

                          <div className="sale-item-floor">
                            <div
                              className="cursor-pointer sale-show-more-detail"
                              onClick={() => popupHandle(item, floor.id)}
                            >
                              {language === "en"
                                ? floor.remarks_ttl_en
                                : floor.remarks_ttl_tc}
                            </div>
                            <div className="text-center">
                              {floor.floor_plan && (
                                <div className="mobile-btn-wrapper">
                                  <img
                                    src="/images/doc.svg"
                                    className="cursor-pointer"
                                    onClick={() => lightBoxHandle(floor)}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="text-center">
                              {floor.image.length > 0 && (
                                <div className="mobile-btn-wrapper">
                                  <img
                                    src="/images/camera.svg"
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sliderHandle(
                                        language === "en"
                                          ? item.title_en
                                          : item.title_tc,
                                        floor
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>

                            <div className="text-center">
                              {floor.video && (
                                <div onClick={() => showVideo(floor.video)}>
                                  <div className="mobile-btn-wrapper">
                                    <svg
                                      className="w-4 h-4"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        width: 17,
                                        height: 17,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="sale-table-footer">
                    <div className="stf-btn" onClick={sendRequestHandle}>
                      <i className="material-icons">arrow_forward</i>
                      {t("send_sale_request")}
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
      <LightBox source={source} closeLightBox={closeLightBox} />
      {/*       <Sliders slider={slider} closeSlider={closeSlider} /> */}
      {floorId && (
        <Popup
          detail={detail}
          closeDetail={closeDetail}
          floorId={floorId}
          type="Sale"
        />
      )}
      {videoSource && (
        <div className="video-scene">
          <div className="video-scene-close">
            <i className="material-icons" onClick={closeVideo}>
              close
            </i>
          </div>
          <div className="video-scene-content">
            <video
              width="100%"
              height="auto"
              controls
              controlsList="nodownload"
              autoPlay
            >
              <source src={config.baseURL + "/uploads/" + videoSource} />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sale;

import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../notRedux";
import { Link } from "react-router-dom";
import { mobileMenu } from "../notRedux";

function PageHeader() {
  const { t, i18n } = useTranslation();
  const languageInit = localStorage.getItem("language") || "tc";
  const [language, setLanguage] = React.useState(languageInit);
  const [menuFlag, setMenuFlag] = React.useState(true);

  const toggleHandler = (lang) => {
    localStorage.setItem("language", lang);
    document.body.className = `language-${lang}`;
    i18n.changeLanguage(lang);
    const desc = document.getElementById("description");
    setLanguage(lang);
    LanguageContext.publish(lang);
  };

  const menuHandle = () => {
    // setMenuFlag(!menuFlag);
    mobileMenu.publish(!menuFlag);
  };

  React.useEffect(() => {
    mobileMenu.subscribe("pageHeader", (data) => {
      setMenuFlag(data);
      const wWidth = window.innerWidth;
      console.log(wWidth);
      if (wWidth > 960) {
        document.getElementsByTagName("body")[0].className = "";
        return false;
      }
      if (data) {
        document.getElementsByTagName("body")[0].className = "";
      } else {
        document.getElementsByTagName("body")[0].className =
          "overflow-y-hidden";
      }
    });

    toggleHandler(languageInit);
  }, []);

  return (
    <div className="header">
      <div className="row">
        <div className="header-left col-auto">
          <Link to="/home">
            <img src="/images/logo.gif" alt="" className="logo" />
          </Link>
          <img
            src="/images/made-to-measure-mb.png"
            alt=""
            className="mb-slogan"
          />
        </div>
        <div className="header-right col text-right">
          <span
            className={
              "mr-3 language-tc cursor-pointer" +
              (language === "tc" ? " active" : "")
            }
            onClick={() => toggleHandler("tc")}
          >
            中文
          </span>

          <span
            className={
              "language-en cursor-pointer" +
              (language === "en" ? " active" : "")
            }
            onClick={() => toggleHandler("en")}
          >
            Eng
          </span>
          <i className="material-icons mobile-trigger" onClick={menuHandle}>
            {menuFlag ? "dehaze" : "clear"}
          </i>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;

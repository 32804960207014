import React, { Suspense } from "react";
import Spinner from "./components/Spinner";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import WebsiteMenu from "./components/WebsiteMenu";
import PageHeader from "./components/PageHeader";
import Sitemap from "./components/Sitemap";
import Footer from "./components/Footer";
import Disclaimer from "./components/Disclaimer";
import Individual from "./components/Individual";
import PropertyPortfolio from "./components/PropertyPortfolio";
import Leasing from "./components/Leasing";
import Sale from "./components/Sale";
import News from "./components/News";
import ContactUs from "./components/ContactUs";
import Subscribe from "./components/Subscribe";
import Independent from "./components/Independent";
import MobileMenu from "./components/MobileMenu";
import { isIE } from "react-device-detect";
import { polyfill } from "es6-promise";
import PopupSliders from "./partial/PopupSliders";
import CustomLightBox from "./partial/CustomLightBox";
import PageNotFound from "./components/PageNotFound";
polyfill();

function App() {
  if (isIE) {
    window.location.href =
      "https://www.microsoft.com/en-us/edge?form=MY01BV&OCID=MY01BV";
    return false;
  }
  return (
    <Suspense fallback={<Spinner />}>
      <div className="app">
        <BrowserRouter>
          <PageHeader />
          <div className="d-flex" style={{ position: "relative" }}>
            <WebsiteMenu />
            <MobileMenu />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route
                path="/our-services"
                render={() => <Individual indvidual_id="1" />}
              />
              <Route
                path="/asset-management"
                render={() => <Individual indvidual_id="2" />}
              />
              <Route
                path="/building-management"
                render={() => <Individual indvidual_id="3" />}
              />
              <Route
                path="/property-agency"
                render={() => <Individual indvidual_id="4" />}
              />
              <Route
                path="/tenancy-management"
                render={() => <Individual indvidual_id="5" />}
              />
              <Route
                path="/property-management"
                render={() => <Individual indvidual_id="6" />}
              />
              <Route
                path="/our-approach"
                render={() => <Individual indvidual_id="7" />}
              />
              <Route
                path="/our-team"
                render={() => <Individual indvidual_id="8" />}
              />
              <Route
                path="/our-company"
                render={() => <Individual indvidual_id="9" />}
              />
              <Route
                path="/property-portfolio/commercial"
                render={() => <PropertyPortfolio portfolio_type="commercial" />}
              />
              <Route
                path="/property-portfolio/residential"
                render={() => (
                  <PropertyPortfolio portfolio_type="residential" />
                )}
              />
              <Route
                path="/leasing/commercial"
                render={() => <Leasing property="commercial" />}
              />
              <Route
                path="/leasing/residential"
                render={() => <Leasing property="residential" />}
              />
              <Route path="/for-sale/commercial-residential" component={Sale} />
              <Route path="/news" component={News} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/subscribe" component={Subscribe} />
              <Route path="/sitemap" component={Sitemap} />
              <Route path="/disclaimer" component={Disclaimer} />
              <Route path="/:slug" component={Independent} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </div>
          <Footer />
          <PopupSliders />
          <CustomLightBox />
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;

export const LanguageContext = {
  collection: [],
  subscribe: function (id, callback) {
    const o = {
      id,
      callback,
    };
    this.collection.push(o);
  },

  unsubscribe: function (id) {
    const filter = this.collection.filter((order) => order.id !== id);
    this.collection = filter;
  },

  publish: function (status) {
    const len = this.collection.length;
    for (let i = 0; i < len; i++) {
      this.collection[i].callback(status);
    }
  },
};

export const mobileMenu = {
  collection: [],
  status: "",
  subscribe: function (id, callback) {
    const o = {
      id,
      callback,
    };
    this.collection.push(o);
  },

  unsubscribe: function (id) {
    const filter = this.collection.filter((order) => order.id !== id);
    this.collection = filter;
  },

  publish: function (status) {
    this.status = status;
    const len = this.collection.length;
    for (let i = 0; i < len; i++) {
      this.collection[i].callback(status);
    }
  },

  get: function () {
    return this.status;
  },
};

export const RequestContext = {
  request: [],
  title: "",
  add: function (item_id, floor_id, item, floor) {
    this.request.push({
      item_id,
      floor_id,
      item,
      floor,
    });
  },
  remove: function (item_id, floor_id) {
    this.request = this.request.filter(
      (item) => item.item_id != item_id || item.floor_id != floor_id
    );
  },
  reset: function () {
    this.request = [];
    this.title = "";
  },
};

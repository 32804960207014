import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";

// SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);

function Home() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState({});
  const [link, setLink] = React.useState("");

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/home`,
    }).then((res) => {
      setData(res.data);
      const num = Math.floor(Math.random() * 4) + 1;
      const link = res.data.link[0];
      switch (num) {
        case 1:
          setLink(link.link_image_1);
          break;
        case 2:
          setLink(link.link_image_2);
          break;
        case 3:
          setLink(link.link_image_3);
          break;
        case 4:
          setLink(link.link_image_4);
          break;
      }
    });
  }, []);

  return (
    <div className="home pb-4 page-content">
      {link && (
        <div>
          <div className="home-banner">
            <Swiper spaceBetween={10} slidesPerView={1} autoplay>
              {data.banner.map((item, index) => {
                return (
                  <SwiperSlide className="swiper-slide" key={index}>
                    {item.link_en &&
                      item.link_tc &&
                      item.is_external_link == 1 && (
                        <a
                          href={language === "en" ? item.link_en : item.link_tc}
                          target="_blank"
                        >
                          <img
                            src={
                              config.baseURL +
                              "/storage/" +
                              (language === "en"
                                ? item.banner_en
                                : item.banner_tc)
                            }
                            className="img-fluid"
                          />
                        </a>
                      )}

                    {item.link_en &&
                      item.link_tc &&
                      item.is_external_link == 0 && (
                        <Link
                          to={language === "en" ? item.link_en : item.link_tc}
                        >
                          <img
                            src={
                              config.baseURL +
                              "/storage/" +
                              (language === "en"
                                ? item.banner_en
                                : item.banner_tc)
                            }
                            className="img-fluid"
                          />
                        </Link>
                      )}

                    {!item.link_en && !item.link_tc && (
                      <img
                        src={
                          config.baseURL +
                          "/storage/" +
                          (language === "en" ? item.banner_en : item.banner_tc)
                        }
                        className="img-fluid"
                      />
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="home-latest-news-wrapper">
            <div className="home-latest-news">
              <h3>{t("latest_news")}</h3>
              {data.news &&
                data.news.data.map((item, index) => {
                  return (
                    <div key={index} className="home-lates-news-item">
                      <span className="news-date">{item.publish}</span>
                      <Link
                        to={"/news?news_id=" + item.id}
                        className="news-ttl"
                      >
                        {language === "en"
                          ? item.title_en.length > 50
                            ? item.title_en.substring(0, 47) + "..."
                            : item.title_en
                          : item.title_tc.length > 50
                          ? item.title_tc.substring(0, 47) + "..."
                          : item.title_tc}
                      </Link>
                    </div>
                  );
                })}
            </div>
            <div className="home-latest-news-subscribe">
              {/*               <span className="home-subscribe-ttl">{t("subscribe")}</span>
              <Link to="/subscribe" className="home-subscribe-link">
                {t("subscribe_link")}
              </Link> */}

              <Link to="/subscribe" className="home-subscribe-link">
                <img
                  src={config.baseURL + "/storage/" + link}
                  className="img-fluid"
                />
                <div className="hsl-ttl">
                  <i className="material-icons">exit_to_app</i>
                  <span>{t("subscribe_updates")}</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;

import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import config from "../config";
import { PopupSlidersControl } from "../global/controller";

import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation]);

function PopupSliders() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [title, setTitle] = React.useState("");
  const [slider, setSlider] = React.useState([]);
  const [index, setIndex] = React.useState(0);

  PopupSlidersControl.init((title, slider, indexInit) => {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    setTitle(title);
    setIndex(indexInit);
    setSlider(slider);
  });

  const closeSlider = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "visible";
    setTitle("");
    setSlider([]);
  };

  return (
    <div className={"popup-sliders" + (slider.length > 0 ? " active" : "")}>
      <div className="popup-sliders-close" onClick={closeSlider}>
        <i className="material-icons">close</i>
      </div>
      <div className="ps-content">
        {slider.length > 0 && (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            autoHeight={true}
            initialSlide={index}
            navigation
          >
            {slider.map((item, index) => {
              return (
                <SwiperSlide className="swiper-slide" key={index}>
                  <div className={"ps-item-wrapper " + item.info.direction}>
                    <div className="swiper-slide-top">
                      {title} -&nbsp;
                      {language === "en" ? item.title_en : item.title_tc}
                    </div>
                    <img
                      src={`${config.baseURL}/storage/${item.image}`}
                      className={"ps-item-image " + item.info.direction}
                    />
                    <div
                      className="swiper-slide-content bg-white"
                      style={{
                        backgroundImage: `url(
                      ${config.baseURL}/storage/${item.image}
                    )`,
                      }}
                    ></div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
}

export default PopupSliders;

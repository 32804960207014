import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { mobileMenu } from "../notRedux";

function WebsiteMenu() {
  const location = useLocation();
  const pathname = location.pathname;
  const { t, i18n } = useTranslation();

  return (
    <div className="website-menu">
      <div className="wm-slogan">
        <img src="/images/made-to-measure.gif" alt="" />
      </div>
      <div>
        <ul className="wm-main">
          <li
            className={pathname === "/home" || pathname === "/" ? "active" : ""}
          >
            <NavLink className="wm-main-link text-decoration-none" to="/home">
              {t("home")}
            </NavLink>
          </li>
          <li
            className={
              pathname === "/our-services" ||
              pathname === "/asset-management" ||
              pathname === "/building-management" ||
              pathname === "/property-agency" ||
              pathname === "/tenancy-management" ||
              pathname === "/property-management"
                ? "active"
                : ""
            }
          >
            <NavLink
              className="wm-main-link text-decoration-none"
              to="/our-services"
            >
              {t("our_services")}
            </NavLink>
            <ul className="wm-sub pt-3">
              <li className={pathname === "/asset-management" ? "active" : ""}>
                <NavLink
                  to="/asset-management"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("asset_management")}
                </NavLink>
              </li>
              <li
                className={pathname === "/building-management" ? "active" : ""}
              >
                <NavLink
                  to="/building-management"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("building_management")}
                </NavLink>
              </li>
              <li className={pathname === "/property-agency" ? "active" : ""}>
                <NavLink
                  to="/property-agency"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("property_agency")}
                </NavLink>
              </li>
              <li
                className={pathname === "/tenancy-management" ? "active" : ""}
              >
                <NavLink
                  to="/tenancy-management"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("tenancy_management")}
                </NavLink>
              </li>
              <li
                className={pathname === "/property-management" ? "active" : ""}
              >
                <NavLink
                  to="/property-management"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("property_management")}
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={pathname === "/our-approach" ? "active" : ""}>
            <NavLink
              to="/our-approach"
              className="wm-main-link text-decoration-none"
            >
              {t("our_approach")}
            </NavLink>
          </li>
          <li className={pathname === "/our-team" ? "active" : ""}>
            <NavLink
              to="/our-team"
              className="wm-main-link text-decoration-none"
            >
              {t("our_team")}
            </NavLink>
          </li>
          <li className={pathname === "/our-company" ? "active" : ""}>
            <NavLink
              to="/our-company"
              className="wm-main-link text-decoration-none"
            >
              {t("our_company")}
            </NavLink>
          </li>
          <li
            className={
              pathname === "/property-portfolio/commercial" ||
              pathname === "/property-portfolio/residential"
                ? "active"
                : ""
            }
          >
            <NavLink
              to="/property-portfolio/commercial"
              className="wm-main-link text-decoration-none"
            >
              {t("property_portfolio")}
            </NavLink>
            <ul className="wm-sub pt-3">
              <li
                className={
                  pathname === "/property-portfolio/commercial" ? "active" : ""
                }
              >
                <NavLink
                  to="/property-portfolio/commercial"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("commercial")}
                </NavLink>
              </li>
              <li
                className={
                  pathname === "/property-portfolio/residential" ? "active" : ""
                }
              >
                <NavLink
                  to="/property-portfolio/residential"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("residential")}
                </NavLink>
              </li>
            </ul>
          </li>
          <li
            className={
              pathname === "/leasing/commercial" ||
              pathname === "/leasing/residential"
                ? "active"
                : ""
            }
          >
            <NavLink
              to="/leasing/commercial"
              className="wm-main-link text-decoration-none"
            >
              {t("leasing")}
            </NavLink>
            <ul className="wm-sub pt-3">
              <li
                className={pathname === "/leasing/commercial" ? "active" : ""}
              >
                <NavLink
                  to="/leasing/commercial"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("commercial")}
                </NavLink>
              </li>
              <li
                className={pathname === "/leasing/residential" ? "active" : ""}
              >
                <NavLink
                  to="/leasing/residential"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("residential")}
                </NavLink>
              </li>
            </ul>
          </li>
          <li
            className={
              pathname === "/for-sale/commercial-residential" ? "active" : ""
            }
          >
            <NavLink
              to="/for-sale/commercial-residential"
              className="wm-main-link text-decoration-none"
            >
              {t("for_sale")}
            </NavLink>
            <ul className="wm-sub pt-3">
              <li
                className={
                  pathname === "/for-sale/commercial-residential"
                    ? "active"
                    : ""
                }
              >
                <NavLink
                  to="/for-sale/commercial-residential"
                  className="wm-sub-link text-decoration-none"
                >
                  {t("commercial_residential")}
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={pathname === "/news" ? "active" : ""}>
            <NavLink to="/news" className="wm-main-link text-decoration-none">
              {t("news")}
            </NavLink>
          </li>
          <li className={pathname === "/contact-us" ? "active" : ""}>
            <NavLink
              to="/contact-us"
              className="wm-main-link text-decoration-none"
            >
              {t("contact_us")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WebsiteMenu;

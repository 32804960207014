import React from "react";
import PageTitle from "./PageTitle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";
import axios from "axios";

function Sitemap() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/independent/list`,
    }).then((res) => {
      console.log(res);
      setData(res.data);
    });
  }, []);

  return (
    <div className="sitemap page-content">
      <PageTitle title={t("sitemap")} />
      <ul className="sitemap-content">
        <li className="sitemap_firstlevel">
          <Link to="/" className="sitemap-main-link">
            {t("home")}
          </Link>
        </li>
        <li className="sitemap_firstlevel">
          <Link to="/our-services" className="sitemap-main-link">
            {t("our_services")}
          </Link>
          <ul>
            <li>
              <Link
                to="/asset-management"
                className="wm-sub-link text-decoration-none"
              >
                {t("asset_management")}
              </Link>
            </li>
            <li>
              <Link
                to="/building-management"
                className="wm-sub-link text-decoration-none"
              >
                {t("building_management")}
              </Link>
            </li>
            <li>
              <Link
                to="/property-agency"
                className="wm-sub-link text-decoration-none"
              >
                {t("property_agency")}
              </Link>
            </li>
            <li>
              <Link
                to="/tenancy-management"
                className="wm-sub-link text-decoration-none"
              >
                {t("tenancy_management")}
              </Link>
            </li>
            <li>
              <Link
                to="/property-management"
                className="wm-sub-link text-decoration-none"
              >
                {t("property_management")}
              </Link>
            </li>
          </ul>
        </li>

        <li className="sitemap_firstlevel">
          <Link
            to="/our-approach"
            className="wm-main-link text-decoration-none"
          >
            {t("our_approach")}
          </Link>
        </li>

        <li className="sitemap_firstlevel">
          <Link to="/our-team" className="wm-main-link text-decoration-none">
            {t("our_team")}
          </Link>
        </li>

        <li className="sitemap_firstlevel">
          <Link to="/our-company" className="wm-main-link text-decoration-none">
            {t("our_company")}
          </Link>
        </li>

        <li className="sitemap_firstlevel">
          <Link
            to="/property-portfolio/commercial"
            className="wm-main-link text-decoration-none"
          >
            {t("property_portfolio")}
          </Link>
          <ul>
            <li>
              <Link
                to="/property-portfolio/commercial"
                className="wm-sub-link text-decoration-none"
              >
                {t("commercial")}
              </Link>
            </li>
            <li>
              <Link
                to="/property-portfolio/residential"
                className="wm-sub-link text-decoration-none"
              >
                {t("residential")}
              </Link>
            </li>
          </ul>
        </li>

        <li className="sitemap_firstlevel">
          <Link
            to="/leasing/commercial"
            className="wm-main-link text-decoration-none"
          >
            {t("leasing")}
          </Link>
          <ul>
            <li>
              <Link
                to="/leasing/commercial"
                className="wm-sub-link text-decoration-none"
              >
                {t("commercial")}
              </Link>
            </li>
            <li>
              <Link
                to="/leasing/residential"
                className="wm-sub-link text-decoration-none"
              >
                {t("residential")}
              </Link>
            </li>
          </ul>
        </li>

        <li className="sitemap_firstlevel">
          <Link
            to="/for-sale/commercial-residential"
            className="wm-main-link text-decoration-none"
          >
            {t("for_sale")}
          </Link>
          <ul>
            <li>
              <Link
                to="/for-sale/commercial-residential"
                className="wm-sub-link text-decoration-none"
              >
                {t("commercial_residential")}
              </Link>
            </li>
          </ul>
        </li>

        <li className="sitemap_firstlevel">
          <Link to="/news" className="wm-main-link text-decoration-none">
            {t("news")}
          </Link>
        </li>
        <li className="sitemap_firstlevel">
          <Link to="/contact-us" className="wm-main-link text-decoration-none">
            {t("contact_us")}
          </Link>
        </li>

        {data && (
          <li className="sitemap_firstlevel">
            <a href="#" className="wm-main-link text-decoration-none">
              {t("others")}
            </a>
            <ul>
              {data.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={"/" + item.slug}
                      className="wm-sub-link text-decoration-none"
                    >
                      {language === "en"
                        ? item.title_en + " / " + item.sub_title_en
                        : item.title_tc + " / " + item.sub_title_tc}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Sitemap;

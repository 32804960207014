const config = {
  init: function () {
    this.baseURL = "https://backend.cpps.com.hk";
    // this.baseURL = "https://cpps-backend.hkictech.com";
    this.baseAPI = this.baseURL + "/api";
    this.enquiry = "";
  },
  setEnquiry: function (enquiry) {
    this.enquiry = enquiry;
  },
};
config.init();

export default config;

import React from "react";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../config";
import { useLocation } from "react-router-dom";
import $ from "jquery";

function News() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState([]);
  const [current, setCurrent] = React.useState({});

  const location = window.location.href;
  const param = location.split("news_id=").pop();

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/news`,
    }).then((res) => {
      console.log(res);
      setData(res.data);
      console.log(param);
      console.log(res.data.find(item => item.id == param));
      if (param && res.data.find(item => item.id == param)) {
        setCurrent(res.data.find(item => item.id == param));
      } else {
        setCurrent(res.data[0]);
      }
    });
  }, []);

  const next = () => {
    let index = data.findIndex((item) => current.id === item.id);
    if (index < data.length - 1) {
      setCurrent(data[++index]);
    }
  };

  const prev = () => {
    let index = data.findIndex((item) => current.id === item.id);
    if (index > 0) {
      setCurrent(data[--index]);
    }
  };

  const showMobileContent = (e) => {
    const target = e.target;
    const parent = target.closest(".news-item");
    if (parent.dataset.state === "close") {
      parent.classList.add("active");
      target.classList.remove("active");

      parent.setAttribute("data-state", "open");
      $(parent).find(".news-mb-content").slideUp();
    } else {
      parent.classList.remove("active");
      target.classList.add("active");
      parent.setAttribute("data-state", "close");
      $(parent).find(".news-mb-content").slideDown();
    }
  };

  return (
    <div className="news page-content">
      <PageTitle title={t("news")} />
      {data.length > 0 && (
        <div className="d-flex">
          <div className="news-detail">
            <div className="d-flex justify-content-between mb-3">
              <div className="cursor-pointer" onClick={next}>
                <i className="news-btn material-icons">arrow_back_ios</i>
                {t("pervious")}
              </div>
              <div className="cursor-pointer" onClick={prev}>
                {t("next")}
                <i className="news-btn material-icons ml-1">
                  arrow_forward_ios
                </i>
              </div>
            </div>
            <div>{current.publish}</div>
            <div className="news-ttl">
              {language === "en" ? current.title_en : current.title_tc}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  language === "en" ? current.content_en : current.content_tc,
              }}
            ></div>
            {current.image && (
              <div>
                <img
                  src={`${config.baseURL}/storage/` + current.image}
                  className="img-fluid"
                  style={{
                    maxWidth: current.image_max_width
                      ? current.image_max_width + "px"
                      : "100%",
                  }}
                />
              </div>
            )}
          </div>
          <div className="news-list">
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    "news-item" + (current.id === item.id ? " active" : "")
                  }
                  onClick={() => setCurrent(item)}
                >
                  <div className="news-item-header">
                    <div>
                      {language === "en" ? item.title_en : item.title_tc}
                    </div>
                    <div className="news-item-header-ttl">{item.publish}</div>
                    <div
                      className="news-mb-trigger"
                      onClick={showMobileContent}
                    ></div>
                  </div>
                  <div className="news-mb-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          language === "en" ? item.content_en : item.content_tc,
                      }}
                    ></div>
                    {item.image && (
                      <div>
                        <img
                          src={`${config.baseURL}/storage/` + item.image}
                          className="img-fluid"
                          style={{
                            maxWidth: item.image_max_width
                              ? item.image_max_width + "px"
                              : "100%",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default News;

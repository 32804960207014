import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import axios from "axios";
import Staff from "./Staff";
import PageTitle from "./PageTitle";
import { useHistory } from "react-router-dom";

function Independent(props) {
  const { t, i18n } = useTranslation();
  let history = useHistory();

  const language = i18n.language;
  const [data, setData] = React.useState({});
  const slug = props.match.params.slug;

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/independent/${slug}`,
    }).then((res) => {
      console.log(res.data[0]);
      if (res.data[0] == undefined) {
        history.push("*");
      } else {
        setData(res.data[0]);
      }
    });
  }, []);
  return (
    <div className="independent-wrapper page-content">
      {data.title_en && (
        <div className="independent">
          <PageTitle
            title={language === "en" ? data.title_en : data.title_tc}
            subtitle={language === "en" ? data.sub_title_en : data.sub_title_tc}
          />
          <div className="independent-content-wrapper">
            <div
              className="independent-content"
              dangerouslySetInnerHTML={{
                __html: language === "en" ? data.content_en : data.content_tc,
              }}
            ></div>
            {data.pdf && (
              <div>
                <a href={`${config.base}/storage/${data.pdf}`}>DownLoad</a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Independent;

import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import axios from "axios";

function Staff() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/staff`,
    }).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <div className="staff-wrapper">
      {data.map((item, index) => (
        <div key={index} className="staff d-flex">
          <div className="staff-photo">
            <img
              src={config.baseURL + "/storage/" + item.photo}
              style={{ width: "115px" }}
            />
          </div>
          <div className="flex-grow-1 p-3">
            {language === "tc" && (
              <h5 className="staff-name">{item.name_tc}</h5>
            )}
            <h5 className="staff-name">{item.name_en}</h5>
            <div className="staff-title">
              {language === "en" ? item.title_en : item.title_tc}
            </div>
            {item.desc_en && item.desc_tc && (
              <p>{language === "en" ? item.desc_en : item.desc_tc}</p>
            )}
            {language === "en" ? (
              <div
                className="staff-content"
                dangerouslySetInnerHTML={{ __html: item.content_en }}
              ></div>
            ) : (
              <div
                className="staff-content"
                dangerouslySetInnerHTML={{ __html: item.content_tc }}
              ></div>
            )}
            <p>
              <img src="/images/icon_mail.gif" className="mr-2" />
              <a
                href={"mailto:" + item.email}
                className="text-decoration-none text-deep-blue"
              >
                {item.email}
              </a>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Staff;

import React from "react";

function LightBox({ source, closeLightBox }) {
  return (
    <div className={"light-box" + (source ? " active" : "")}>
      <div className="lb-close" onClick={closeLightBox}>
        <i className="material-icons">close</i>
      </div>
      <div className="lb-content">
        {source && <img src={source} className="img-fluid" />}
      </div>
    </div>
  );
}

export default LightBox;

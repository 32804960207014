import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RequestContext } from "../notRedux";
import config from "../config";

function Popup({ detail, closeDetail, floorId, type }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const floor = detail.floor.filter((item) => item.id == floorId)[0];
  const history = useHistory();

  const setEnquiry = () => {
    const floor = detail.floor.filter((item) => item.id == floorId)[0];
    const item_id = detail.id;
    const floor_id = floorId;

    const title = language === "en" ? detail.title_en : detail.title_tc;
    const languageFloor = language === "en" ? floor.floor_en : floor.floor_tc;
    const languageType = type === "Leasing" ? t("leasing") : t("for_sale");

    RequestContext.reset();
    RequestContext.title = languageType;
    RequestContext.add(item_id, floor_id, title, languageFloor);

    history.push("/contact-us");
  };

  return (
    <div className={"popup" + (detail.floor ? " active" : "")}>
      <div className="p-close" onClick={closeDetail}>
        <i className="material-icons">close</i>
      </div>

      <div className="p-content">
        <div className="p-content-top">
          <div className="font-weight-bolder text-deep-blue h6">
            {language === "en" ? detail.title_en : detail.title_tc}
          </div>
          <div className="font-weight-bold mb-2">
            {language === "en" ? detail.address_en : detail.address_tc}
          </div>
          <div className="row">
            <div className="col p-content-ttl">{t("floor_unit")}</div>
            <div className="col">
              {language === "en" ? floor.floor_en : floor.floor_tc}
            </div>
          </div>
          <div className="row">
            <div className="col p-content-ttl">{t("gross_area_1")}</div>
            <div className="col align-top">
              {language === "en" && detail.property === "residential" && type === "Leasing" ? floor.gross_en : ""}
              {language === "tc" && detail.property === "residential" && type === "Leasing" ? floor.gross_tc : ""}
              {language === "en" && detail.property === "residential" && type === "Sale" ? floor.gross_area_en : ""}
              {language === "tc" && detail.property === "residential" && type === "Sale" ? floor.gross_area_tc : ""}

              {language === "en" && detail.property === "commercial" && type === "Leasing" ? floor.gross_en : ""}
              {language === "tc" && detail.property === "commercial" && type === "Leasing" ? floor.gross_tc : ""}
              {language === "en" && detail.property === "commercial" && type === "Sale" ? floor.gross_area_en : ""}
              {language === "tc" && detail.property === "commercial" && type === "Sale" ? floor.gross_area_tc : ""}
{/*               {language === "tc" && type === "Leasing"
                ? floor.gross_tc
                : floor.gross_area_tc} */}
            </div>
          </div>
          {detail.property === "residential" && (
            <div className="row">
              <div className="col p-content-ttl">{t("saleable_area_1")}</div>
              <div className="col align-top">

              {language === "en" && detail.property === "residential" && type === "Leasing" ? floor.saleable_en : ""}
              {language === "tc" && detail.property === "residential" && type === "Leasing" ? floor.saleable_tc : ""}
              {language === "en" && detail.property === "residential" && type === "Sale" ? floor.saleable_area_en : ""}
              {language === "tc" && detail.property === "residential" && type === "Sale" ? floor.saleable_area_tc : ""}

              {language === "en" && detail.property === "commercial" && type === "Leasing" ? floor.saleable_en : ""}
              {language === "tc" && detail.property === "commercial" && type === "Leasing" ? floor.saleable_tc : ""}
              {language === "en" && detail.property === "commercial" && type === "Sale" ? floor.saleable_area_en : ""}
              {language === "tc" && detail.property === "commercial" && type === "Sale" ? floor.saleable_area_tc : ""}

{/*                 {language === "en" && type === "Leasing"
                  ? floor.saleable_area_en
                  : floor.saleable_area_tc} */}
                {/* {language === "tc" && type === "Leasing"
                  ? floor.saleable_tc
                  : floor.saleable_area_tc} */}
              </div>
            </div>
          )}
          {type === "Leasing" && (
            <div className="row">
              <span className="col p-content-ttl">
                {t(`${detail.property}_asking_rental_1`)}
              </span>
              <span className="col align-top">
                {language === "en" ? floor.rental_en : floor.rental_tc}
              </span>
            </div>
          )}
          {type === "Sale" && (
            <div className="row">
              <span className="col p-content-ttl">{t("asking_price")}</span>
              <span className="col">
                {language === "en" ? floor.price_en : floor.price_tc}
              </span>
            </div>
          )}
        </div>
        <div
          className="p-content-floor"
          dangerouslySetInnerHTML={{
            __html: language === "en" ? floor.remarks_en : floor.remarks_tc,
          }}
        ></div>
        <div className="popup-contact-us-link text-center my-3">
          <span className="link" onClick={setEnquiry}>
            <i className="material-icons">arrow_forward</i>
            {type === "Leasing" && t("send_leasing_request")}
            {type === "Sale" && t("send_sale_request")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Popup;

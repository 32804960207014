import React from "react";

function PageTitle({ title, subtitle, customStyle }) {
  return (
    <div className="page-title">
      <div>
        <h1 style={customStyle}>{title}</h1>
        {subtitle && <h3>{subtitle}</h3>}
      </div>
    </div>
  );
}

export default PageTitle;

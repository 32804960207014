import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import axios from "axios";
import PageTitle from "./PageTitle";
import { useForm } from "react-hook-form";
import Spinner from "./Spinner";

function Subscribe() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { register, handleSubmit, watch, errors } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const onSubmit = (data) => {
    setLoading(true);
    // console.log(data);
    const formData = new FormData();
    formData.append("company", data.company);
    formData.append("contact", data.contact);
    formData.append("email", data.email);
    axios({
      method: "post",
      url: `${config.baseAPI}/subscribe`,
      data: formData,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.message === "success") {
          document.getElementById("enquiry-form").reset();
          setMessage(t("your_subscribe_success"));
        } else {
          setMessage(t("subscribe_error"));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="subscribe page-content">
      <PageTitle title={t("subscribe")} />
      <form id="enquiry-form" className="subscribe-form">
        <div className="d-flex flex-wrap">
          <div className="sub-form-ttl mb-2">{t("contact_name")}</div>
          <div className="sub-form-txt mb-2">
            <input
              type="text"
              name="contact"
              className="w-100"
              ref={register({ required: true })}
            />
            {errors.contact && errors.contact.type === "required" && (
              <p className="text-black">{t("contact_required")}</p>
            )}
          </div>

          <div className="sub-form-ttl mb-2">{t("company_name")}</div>
          <div className="sub-form-txt mb-2">
            <input
              type="text"
              name="company"
              className="w-100"
              ref={register({ required: true })}
            />
            {errors.company && errors.company.type === "required" && (
              <p className="text-black">{t("company_required")}</p>
            )}
          </div>

          <div className="sub-form-ttl mb-2">{t("email_address")}</div>
          <div className="sub-form-txt mb-2">
            <input
              type="text"
              name="email"
              className="w-100"
              ref={register({
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.email && errors.email.type === "required" && (
              <p className="text-black">{t("email_required")}</p>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <p className="text-black">{t("email_format_invalid")}</p>
            )}
          </div>
        </div>
        <div className="mt-3">
          <button className="btn sub-btn" onClick={handleSubmit(onSubmit)}>
            {t("submit")}
          </button>
        </div>
      </form>
      {loading && <Spinner />}
      {message && (
        <div className="mx-3">
          <div className="alert alert-info">{message}</div>
        </div>
      )}
    </div>
  );
}

export default Subscribe;

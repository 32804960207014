import React from "react";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../config";
import LightBox from "./LightBox";
// import Sliders from "./Sliders";
import Popup from "./Popup";
import $ from "jquery";
import { RequestContext } from "../notRedux";
import { useHistory } from "react-router-dom";
import { PopupSlidersControl } from "../global/controller";

function Leasing({ property }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState([]);
  const [source, setSource] = React.useState("");
  // const [slider, setSlider] = React.useState([]);
  const [detail, setDetail] = React.useState({});
  const [floorId, setFloorId] = React.useState("");
  const history = useHistory();

  RequestContext.reset();

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/leasing/${property}`,
    }).then((res) => {
      console.log(res);
      setData(res.data);

      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id");
      if (id) {
        const num = "item-" + id;
        const element = document.getElementById(num);
        element.scrollIntoView();
      }
    });
  }, [property]);

  const closeLightBox = () => {
    document.getElementsByTagName("body")[0].className = "";
    setSource("");
  };

  const lightBoxHandle = (floor) => {
    /*     const item = data.find((item) => item.id === item_id);
    const floor = item.floor.find((floor) => floor.id === floor_id); */
    document.getElementsByTagName("body")[0].className = "overflow-y-hidden";
    const src = config.baseURL + "/storage/" + floor.floor_plan;
    setSource(src);
  };

  const sliderHandle = (title, floor) => {
    /*     const item = data.find((item) => item.id === item_id);
    const floor = item.floor.find((floor) => floor.id === floor_id); */
    // document.getElementsByTagName("body")[0].className = "overflow-y-hidden";
    // const image = floor.image;
    PopupSlidersControl.execute(title, floor.image);

    // setSlider(floor.image);
  };

  /* const closeSlider = () => {
    document.getElementsByTagName("body")[0].className = "";
    setSlider([]);
  }; */

  const popupHandle = (item, floorId) => {
    console.log(floorId);
    document.getElementsByTagName("body")[0].className = "overflow-y-hidden";
    setDetail(item);
    setFloorId(floorId);
  };

  const closeDetail = () => {
    document.getElementsByTagName("body")[0].className = "";
    setFloorId("");
    setDetail({});
  };

  const openContentHandle = (e) => {
    const target = e.target;
    const parent = target.closest(".leasing-item");
    if (parent.dataset.state === "close") {
      target.classList.add("active");
      parent.classList.add("active");
      parent.setAttribute("data-state", "open");
      $(parent).find(".leasing-content-wapper").slideDown();
    } else {
      target.classList.remove("active");
      parent.classList.remove("active");
      parent.setAttribute("data-state", "close");
      $(parent).find(".leasing-content-wapper").slideUp();
    }
  };

  const requestHandle = (e) => {
    const target = e.target;
    const value = target.value;
    const checked = target.checked;
    const item_id = target.dataset.itemId;
    const floor_id = target.dataset.floorId;
    const item = target.dataset.item;
    const floor = target.dataset.floor;
    if (checked) {
      RequestContext.add(item_id, floor_id, item, floor);
    } else {
      RequestContext.remove(item_id, floor_id);
    }
  };

  const sendRequestHandle = () => {
    RequestContext.title = t("leasing");
    history.push("/contact-us");
  };

  return (
    <div className="leasing page-content">
      <PageTitle title={t("leasing")} subtitle={t(property)} />
      {data &&
        data.map((item, index) => {
          return (
            item.floor.length > 0 && (
              <div
                key={index}
                id={"item-" + item.id}
                className="leasing-item"
                data-state="close"
              >
                <div className="li-name">
                  {language === "en" ? item.title_en : item.title_tc}
                  <div
                    className="leasing-trigger "
                    onClick={openContentHandle}
                  ></div>
                </div>
                <div className="leasing-content-wapper">
                  <div className="position-relative">
                    <div className="li-address">
                      <b>{t("address")}:</b>
                      <div>
                        {language === "en" ? item.address_en : item.address_tc}
                      </div>
                    </div>
                    <div className="leasing-property-portfolio-link">
                      {item.portfolio_id && (
                        <Link
                          to={`/property-portfolio/${property}?id=${item.id}`}
                        >
                          <span>{t("view_property_description")}</span>
                          <i className="material-icons">leaderboard</i>
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className="leasing-table">
                    <div className="leasing-row leasing-header">
                      <div style={{ width: "10px" }}></div>
                      <div className="text-deep-grey align-top">
                        {t("floor_unit")}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: t("gross_area") }}
                        className="text-deep-grey align-top"
                      ></div>
                      {property === "residential" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t("saleable_area"),
                          }}
                          className="text-deep-grey align-top"
                        ></div>
                      ) : (
                        ""
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t(`${property}_asking_rental`),
                        }}
                        className="text-deep-grey align-top"
                      ></div>
                      <div className="text-deep-grey align-top leasing-remarks">
                        {t("remarks")}
                      </div>
                      <div className="text-center text-deep-grey align-top">
                        {t("floor_plan")}
                      </div>
                      <div className="text-center text-deep-grey align-top">
                        {t("interior_images")}
                      </div>
                    </div>
                    {item.floor.map((floor, index) => {
                      return (
                        <div className="leasing-row" key={index}>
                          <div style={{ width: "10px" }}>
                            <input
                              type="checkbox"
                              onClick={(e) => requestHandle(e)}
                              data-item-id={item.id}
                              data-floor-id={floor.id}
                              data-item={
                                language === "en"
                                  ? item.title_en
                                  : item.title_tc
                              }
                              data-floor={
                                language === "en"
                                  ? floor.floor_en
                                  : floor.floor_tc
                              }
                            />
                          </div>
                          <div className="row">
                            <div className="col mobile-leasing-ttl">
                              {t("floor_unit")}
                            </div>
                            <div className="col">
                              {language === "en"
                                ? floor.floor_en
                                : floor.floor_tc}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col mobile-leasing-ttl">
                              {t("gross_area_1")}
                            </div>
                            <div className="col">
                              {language === "en"
                                ? floor.gross_en
                                : floor.gross_tc}
                            </div>
                          </div>
                          {property == "residential" ? (
                            <div className="row">
                              <div className="col mobile-leasing-ttl">
                                {t("saleable_area_1")}
                              </div>
                              <div className="col">
                                {language === "en"
                                  ? floor.saleable_en
                                  : floor.saleable_tc}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            <div className="col mobile-leasing-ttl">
                              {property === "commercial" &&
                                t("commercial_asking_rental_1")}
                              {property === "residential" &&
                                t("residential_asking_rental_1")}
                            </div>
                            <div className="col">
                              {language === "en"
                                ? floor.rental_en
                                : floor.rental_tc}
                            </div>
                          </div>

                          <div
                            className="cursor-pointer leasing-show-more-detail only-for-desktop"
                            onClick={() => popupHandle(item, floor.id)}
                          >
                            {language === "en"
                              ? floor.remarks_ttl_en
                              : floor.remarks_ttl_tc}
                          </div>
                          <div className="text-center only-for-desktop">
                            {floor.floor_plan && (
                              <img
                                src="/images/icon_floorplan.gif"
                                className="cursor-pointer"
                                onClick={() => lightBoxHandle(floor)}
                              />
                            )}
                          </div>
                          <div className="text-center only-for-desktop">
                            {floor.image && floor.image.length > 0 && (
                              <img
                                src="/images/icon_camera.gif"
                                className="cursor-pointer"
                                onClick={() =>
                                  sliderHandle(
                                    language === "en"
                                      ? item.title_en
                                      : item.title_tc,
                                    floor
                                  )
                                }
                              />
                            )}
                          </div>

                          <div className="leasing-item-floor">
                            <div
                              className="cursor-pointer leasing-show-more-detail"
                              onClick={() => popupHandle(item, floor.id)}
                            >
                              {language === "en"
                                ? floor.remarks_ttl_en
                                : floor.remarks_ttl_tc}
                            </div>
                            <div className="text-center">
                              {floor.floor_plan && (
                                <div className="mobile-btn-wrapper">
                                  <img
                                    src="/images/doc.svg"
                                    className="cursor-pointer"
                                    onClick={() => lightBoxHandle(floor)}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="text-center">
                              {floor.image && floor.image.length > 0 && (
                                <div className="mobile-btn-wrapper">
                                  <img
                                    src="/images/camera.svg"
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sliderHandle(
                                        language === "en"
                                          ? item.title_en
                                          : item.title_tc,
                                        floor
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="leasing-table-footer">
                    <div className="ltf-btn" onClick={sendRequestHandle}>
                      <i className="material-icons">arrow_forward</i>
                      {t("send_leasing_request")}
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
      <LightBox source={source} closeLightBox={closeLightBox} />
      {/* <Sliders slider={slider} closeSlider={closeSlider} /> */}
      {floorId && (
        <Popup
          detail={detail}
          closeDetail={closeDetail}
          floorId={floorId}
          type="Leasing"
        />
      )}
    </div>
  );
}

export default Leasing;

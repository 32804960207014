import React from "react";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Spinner from "./Spinner";
import axios from "axios";
import config from "../config";
import { RequestContext } from "../notRedux";

function ContactUs() {
  const { t, i18n } = useTranslation();
  // const language = i18n.language;

  const { register, handleSubmit, watch, errors } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [enquiry, setEnquiry] = React.useState("");
  const [text, setText] = React.useState("");

  const onSubmit = (data) => {
    setLoading(true);
    // console.log(data);
    const formData = new FormData();
    formData.append("company", data.company);
    formData.append("contact", data.contact);
    formData.append("email", data.email);
    formData.append("telephone", data.telephone);
    formData.append("address", data.address);
    formData.append("enquiry", data.enquiry);

    axios({
      method: "post",
      url: `${config.baseAPI}/enquiry`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.message === "success") {
          document.getElementById("contactUsForm").reset();
        }
        setEnquiry(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    RequestContext.request.forEach((value) => {
      setText(
        (prev) =>
          (prev += `${value.floor}, ${value.item}, (${RequestContext.title})\n`)
      );
    });
    return () => {
      RequestContext.reset();
    };
  }, []);

  return (
    <div className="contact-us page-content">
      <PageTitle title={t("contact_us")} />
      <div className="d-flex flex-wrap p-4 p-lg-0">
        <div className="cu-form mb-4">
          <div className="contact-us-ttl">{t("contact-us-desc")}</div>
          <div>{t("your_privacy")}</div>
          <div className="text-right mb-3">*{t("mandatory_fields")}</div>
          <form id="contactUsForm">
            <div className="d-flex flex-wrap">
              <div className="cu-form-ttl mb-2">{t("company_name")}*</div>
              <div className="cu-form-txt mb-2">
                <input
                  type="text"
                  name="company"
                  className="w-100"
                  ref={register({ required: true })}
                />
                {errors.company && errors.company.type === "required" && (
                  <p className="text-black">{t("company_required")}</p>
                )}
              </div>

              <div className="cu-form-ttl mb-2">{t("contact_name")}*</div>
              <div className="cu-form-txt mb-2">
                <input
                  type="text"
                  name="contact"
                  className="w-100"
                  ref={register({ required: true })}
                />
                {errors.contact && errors.contact.type === "required" && (
                  <p className="text-black">{t("contact_required")}</p>
                )}
              </div>

              <div className="cu-form-ttl mb-2">{t("email_address")}*</div>
              <div className="cu-form-txt mb-2">
                <input
                  type="text"
                  name="email"
                  className="w-100"
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                {errors.email && errors.email.type === "required" && (
                  <p className="text-black">{t("email_required")}</p>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <p className="text-black">{t("email_format_invalid")}</p>
                )}
              </div>

              <div className="cu-form-ttl mb-2">{t("telephone_number")}</div>
              <div className="cu-form-txt mb-2">
                <input type="text" name="telelphone" className="w-100" />
              </div>

              <div className="cu-form-ttl mb-2">{t("address")}</div>
              <div className="cu-form-txt mb-2">
                <textarea name="address" className="w-100"></textarea>
              </div>

              <div className="cu-form-ttl mb-2">{t("enquiry")}*</div>
              <div className="cu-form-txt mb-2">
                <textarea
                  name="enquiry"
                  className="w-100"
                  rows="10"
                  ref={register({ required: true })}
                  defaultValue={text}
                ></textarea>
                {errors.enquiry && errors.enquiry.type === "required" && (
                  <p className="text-black">{t("enquiry_required")}</p>
                )}
              </div>
            </div>
            <div>
              <button className="btn cu-btn" onClick={handleSubmit(onSubmit)}>
                {t("submit")}
              </button>
            </div>
          </form>
          {enquiry === "success" && (
            <div className="alert alert-info mx-3">{t("enquiry_success")}</div>
          )}
          {enquiry === "fail" && (
            <div className="alert alert-danger mx-3">{t("enquiry_fail")}</div>
          )}
        </div>
        <div className="cu-info">
          <div className="cu-info-title">{t("cp_property_services")}</div>
          <div>{t("licence")}</div>
          <div className="mb-4">{t("license_no")}</div>
          <div className="row">
            <div className="col-3 mb-2">{t("add")}:</div>
            <div className="col-9 mb-2">{t("address_content")}</div>
            <div className="col-3 mb-2">{t("tel")}:</div>
            <div className="col-9 mb-2">(852) 2142 3500</div>
            <div className="col-3 mb-2">{t("fax")}:</div>
            <div className="col-9 mb-2">(852) 2390 5465</div>
            <div className="col-3 mb-2">{t("email")}:</div>
            <div className="col-9 mb-2">
              <a
                href="mailto:info@cpps.com.hk"
                style={{ color: "#041a3b", textDecoration: "none" }}
              >
                info@cpps.com.hk
              </a>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
}

export default ContactUs;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../config";

function Footer() {
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/footer/image`,
    }).then((res) => {
      console.log(res);
      setData(res.data);
    });
  }, []);

  return (
    <div className="footer mx-3 pb-3">
      <div className="footer-txt">
        <div className="mb-1">
          <Link to="/sitemap">{t("sitemap")}</Link>
          <span>|</span>
          <Link to="/disclaimer">{t("disclaimer")}</Link>
        </div>
        <div>
          © 2007-{year} {t("copy_right")}
        </div>
      </div>
      { data && <div>
          <img src={`${config.baseURL}/storage/` + data.image_left} height="50" />
          <img src={`${config.baseURL}/storage/` + data.image_right} height="50" />
        </div>
      }
    </div>
  );
}

export default Footer;

import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import config from "../config";

import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation]);

function Sliders({ slider, closeSlider }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <div className={"sliders" + (slider.length > 0 ? " active" : "")}>
      <div className="sliders-close" onClick={closeSlider}>
        <i className="material-icons">close</i>
      </div>
      <div className="s-content">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          autoHeight={true}
          navigation
        >
          {slider.map((item, index) => {
            return (
              <SwiperSlide className="swiper-slide" key={index}>
                <div
                  className="swiper-slide-content"
                  style={{
                    backgroundImage: `url(
                      ${config.baseURL}/storage/${item.image}
                    )`,
                  }}
                >
                  <div className="swiper-slide-top">
                    {language === "en" ? slider.title_en : slider.title_tc}
                    {language === "en" ? item.title_en : item.title_tc}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default Sliders;

import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import axios from "axios";
import Staff from "./Staff";
import PageTitle from "./PageTitle";

function Individual({ indvidual_id }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/individual/${indvidual_id}`,
    }).then((res) => {
      setData(res.data);
    });
  }, [indvidual_id]);

  return (
    <div className="individual page-content">
      {indvidual_id && (indvidual_id > 6 || indvidual_id == 1) && (
        <picture>
          <source
            srcSet={
              config.baseURL +
              "/storage/" +
              (language === "en" ? data.banner_en : data.banner_tc)
            }
            media="(min-width: 960px)"
          />
          <img
            src={
              config.baseURL +
              "/storage/" +
              (language === "en" ? data.banner_mb_en : data.banner_mb_tc)
            }
            alt=""
            className="individual-banner"
          />
        </picture>
      )}

      {indvidual_id < 7 && indvidual_id != 1 && (
        <PageTitle
          title={t("our_services")}
          subtitle={language === "en" ? data.title_en : data.title_tc}
        />
      )}
      <div className="i-content-wrapper">
        {language === "en" ? (
          <div
            className="i-content"
            dangerouslySetInnerHTML={{ __html: data.content_en }}
          ></div>
        ) : (
          <div
            className="i-content"
            dangerouslySetInnerHTML={{ __html: data.content_tc }}
          ></div>
        )}

        <div className="i-contetn-image text-center">
          {(data.image_en || data.image_tc) && (
            <img
              src={
                config.baseURL +
                "/storage/" +
                (language === "en" ? data.image_en : data.image_tc)
              }
              className="img-fluid"
            />
          )}
        </div>
      </div>
      {indvidual_id == 8 && <Staff />}
    </div>
  );
}

export default Individual;

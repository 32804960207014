import React from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import axios from "axios";
import PageTitle from "./PageTitle";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import {
  PopupSlidersControl,
  CustomLightBoxControl,
} from "../global/controller";

SwiperCore.use([Navigation]);

function PropertyPortfolio({ portfolio_type }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${config.baseAPI}/portfolio/${portfolio_type}`,
    }).then((res) => {
      setData(res.data);
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id");
      if (id) {
        const num = "item-" + id;
        const element = document.getElementById(num);
        element.scrollIntoView();
      }
    });
  }, [portfolio_type]);

  const lightBoxHandle = (id) => {
    const item = data.find((item) => item.id === id);
    let source;
    let title;
    let subTitle;
    if (language === "en") {
      source = config.baseURL + "/storage/" + item.location_en;
      title = item.title_en;
      subTitle = item.address_en;
    } else {
      source = config.baseURL + "/storage/" + item.location_tc;
      title = item.title_tc;
      subTitle = item.address_tc;
    }
    CustomLightBoxControl.execute(source, title, subTitle);
  };

  const zoomInHandle = (title, items, index) => {
    PopupSlidersControl.execute(title, items, index);
  };

  return (
    <div className="property-portfolio page-content">
      <PageTitle title={t("property_portfolio")} subtitle={t(portfolio_type)} />
      {data.length > 0 &&
        data.map((item, index) => {
          return (
            <div key={index} id={"item-" + item.id} className="pp-item">
              <div className="pp-item-content">
                <div className="pp-title">
                  {language === "en" ? item.title_en : item.title_tc}
                </div>
                <div className="pp-address">
                  <div>{t("address")}:</div>
                  <div>
                    {language === "en" ? item.address_en : item.address_tc}
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "en" ? item.content_en : item.content_tc,
                  }}
                ></div>
                <div className="pp-button-wrapper">
                  <span
                    className="pp-button mr-2"
                    onClick={() => lightBoxHandle(item.id)}
                  >
                    {t("location_map")}
                    <img src="/images/arrow.svg" className="pp-icon-2" />
                  </span>
                    <Link
                      to={
                        "/leasing/" +
                        portfolio_type.toLowerCase() +
                        (item.is_leasing ? "?id=" + item.id : "")
                      }
                      className="pp-button text-decoration-none"
                    >
                      {t("available_for_leasing")}
                      <i className="pp-icon material-icons">done</i>
                    </Link>
                </div>
              </div>
              <div className="pp-item-slider">
                {item.sliders.length > 0 && (
                  <Swiper spaceBetween={0} slidesPerView={1} navigation>
                    {item.sliders.map((value, index) => {
                      return (
                        <SwiperSlide
                          className="swiper-slide pp-item-slider-content"
                          key={index}
                        >
                          <div
                            className="pp-item-slider-image"
                            style={{
                              backgroundImage: `url(${config.baseURL}/storage/${value.image})`,
                            }}
                          ></div>
                          <div className="pp-item-slider-control d-flex align-items-center text-white">
                            <div>
                              {language === "en"
                                ? item.title_en
                                : item.title_tc}
                              -
                              {language === "en"
                                ? value.title_en
                                : value.title_tc}
                            </div>
                            <div className="ml-auto">
                              <i
                                className="material-icons cursor-pointer"
                                onClick={() =>
                                  zoomInHandle(
                                    language === "en"
                                      ? item.title_en
                                      : item.title_tc,
                                    item.sliders,
                                    index
                                  )
                                }
                              >
                                zoom_in
                              </i>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PropertyPortfolio;

export const PopupSlidersControl = {
  init: function (callback) {
    this.callback = callback;
  },
  execute: function (title, items, index) {
    this.callback(title, items, index);
  },
};

export const CustomLightBoxControl = {
  init: function (callback) {
    this.callback = callback;
  },
  execute: function (source, title, subTitle = "") {
    this.callback(source, title, subTitle);
  },
};

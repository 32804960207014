import React from "react";
import { CustomLightBoxControl } from "../global/controller";

function CustomLightBox() {
  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [source, setSource] = React.useState("");

  CustomLightBoxControl.init((source, title, subTitle) => {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    setTitle(title);
    setSubTitle(subTitle);
    setSource(source);
  });

  const closeHandle = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "visible";
    setTitle("");
    setSubTitle("");
    setSource("");
  };

  return (
    <div className={"custom-light-box" + (source ? " active" : "")}>
      <div className="clb-close" onClick={closeHandle}>
        <i className="material-icons">close</i>
      </div>
      <div className="clb-content">
        {title && <div className="clb-ttl">{title}</div>}
        {subTitle && (
          <div className="clb-sub-ttl">
            <img src="/images/arrow.svg" className="clb-icon" />
            {subTitle}
          </div>
        )}
        {source && <img src={source} className="img-fluid" />}
      </div>
    </div>
  );
}

export default CustomLightBox;

import React from "react";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";

function Disclaimer() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <div className="disclaimer page-content">
      <PageTitle
        title={t("disclaimer")}
        customStyle={{ textTransform: "uppercase" }}
      />
      {language === "en" && (
        <div className="disclaimer-content">
          <p>
            The material contained in this website has been produced by CP
            Property Services Ltd in accordance with its current practices and
            policies and with the benefit of information currently available to
            it and all reasonable efforts have been made to ensure the accuracy
            of the contents of the pages of the website at the time of
            preparation. CP Property Services Ltd regularly reviews the website
            and where appropriate will update pages to reflect change in
            circumstances.
          </p>
          <p>
            Notwithstanding all efforts made by CP Property Services Ltd to
            ensure the accuracy of the website, no responsibility or liability
            is accepted by CP Property Services Ltd in respect of any use or
            reference to the website in connection with any matter or action
            taken following such use or reference or for any inaccuracies,
            omissions, mis-statements or errors in the said material, or for any
            economic or other loss which may be directly or indirectly sustained
            by any visitor to the website or other person who obtains access to
            the material on the website. The material on this website is for
            general information only and nothing in this website contains
            professional advice or an offer for sale or any binding commitment
            upon CP Property Services Ltd in relation to the availability of
            property or the description of property, and all visitors should
            take advice from a suitably qualified professional in relation to
            any specific query or problem that they may have or in relation to
            any property included in this website, and CP Property Services Ltd
            takes no responsibility for any loss caused as a result of reading
            the contents of this website.
          </p>
          <p>
            No claims, actions or legal proceedings in connection with this
            website brought by any visitor or other person having reference to
            the material on this website will be entertained by CP Property
            Services Ltd.
          </p>
          <p>
            For enquiries please contact:{" "}
            <a href="mailto:info@cpps.com.hk" className="disclaimer-link">
              info@cpps.com.hk
            </a>
          </p>
        </div>
      )}

      {language === "tc" && (
        <div className="disclaimer-content">
          <p>
            本網站內所載之內容和資料，乃中怡物業服務有限公司 (以下簡稱中怡)
            根據現行規範及政策，運用目前可獲得的資訊製作而成，並於準備之時、在合理能力範圍之內，確保網站所有內容正確無誤。中怡定期檢視網站內容，如有需要，將根據情況之改變，適當地更新相關之內容。
          </p>
          <p>
            中怡已致力使網站內容正確無誤，但對於因使用或參考網站內容，或因使用或參考網站內容後，出現有關連之事件或採取有關連之行動，或因所指的網站資料有任何不正確、錯漏或錯誤陳述，或任何網站訪客或其他人士，在查閱網站資料後，直接或間接蒙受經濟或其他損失，中怡一概不會負任何法律上或其他方面的責任。本網站的資料只作一般資訊用途，並無任何內容包含專業建議或物業銷售，或中怡有關於物業供應情況或物業描述方面之任何約束性承諾。所有網站訪客如有特別懷疑或問題，或對網站內所載物業有任何疑問，必須向合適之專業人士尋求建議。因閱讀本網站資料而造成之任何損失，中怡概不負責。
          </p>
          <p>
            對於任何網站訪客或其他人士因參考本網站資料而提出之任何索償、行動或法律訴訟，中怡概不受理。
          </p>
          <p>
            查詢歡迎聯絡：
            <a href="mailto:info@cpps.com.hk" className="disclaimer-link">
              info@cpps.com.hk
            </a>
          </p>
        </div>
      )}
    </div>
  );
}

export default Disclaimer;
